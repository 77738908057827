<template>
  <div id="qll-page">
    <TitleHelper :title="'QLL question editor'"></TitleHelper>
    <div class="display">
      <div v-if="fullSet">

        <div style="color: grey; text-align: left;">
          <div><b>Questions: {{questionCount}}</b></div>
          <div>Source: {{this.fullSet[0].source}}</div>
          <div>Date Correct: {{new Date(this.fullSet[0].dateCorrect).getDate()}} {{monthNames[new Date(this.fullSet[0].dateCorrect).getMonth()+1]}} {{new Date(this.fullSet[0].dateCorrect).getFullYear()}}</div>
          <div>Date Played: {{new Date(this.fullSet[0].datePlayed).getDate()}} {{monthNames[new Date(this.fullSet[0].datePlayed).getMonth()+1]}} {{new Date(this.fullSet[0].datePlayed).getFullYear()}}</div>
          <div>Author: {{this.fullSet[0].author}}</div>
          <div>Purpose: {{this.fullSet[0].purpose}}</div>
          <div v-if="this.fullSet[0].subject">Subject: {{this.fullSet[0].subject}}</div>
        </div>

        <div v-for="(round, n) in round_labels" :key="'round'+n">

          <span v-if="round != 'spares'">
            <div v-for="(q,n) in question_labels" :key="'q'+n+questionMatrix[[round,q]]" class="question" style="position: relative;" :class="{'problem': setMaker.hasOwnProperty([round,q]) && setMaker[[round,q]].hasOwnProperty('status') && setMaker[[round,q]]['status'] == 'problem'}">
              Round: {{round}} Question: {{q}} 
              <div v-if="[round,q] in setMaker">
                <div class="question-text">{{setMaker[[round,q]]['question']}}</div>
                <div class="answer-text">{{setMaker[[round,q]]['answer']}}</div>    
              </div>
              <button class="submit-button" style="position: absolute; right: 5px;" @click="submitQuestion(round,q)">
                button
              </button>
              <div>
                <input v-model="questionMatrix[[round,q]]" /> 
              </div>
              <div>
                <input v-model="answerMatrix[[round,q]]" /> 
              </div>
            </div>
          </span>

          <span v-if="round == 'spares'">
            <div v-for="(q,n) in spare_question_labels" :key="'q'+n" class="question" style="position: relative;" :class="{'problem': setMaker.hasOwnProperty([round,q]) && setMaker[[round,q]].hasOwnProperty('status') && setMaker[[round,q]]['status'] == 'problem'}">
              Round: {{round}} Question: {{q}}
              <div v-if="[round,q] in setMaker">
                <div class="question-text">{{setMaker[[round,q]]['question']}}</div>
                <div class="answer-text">{{setMaker[[round,q]]['answer']}}</div>    
              </div>
              <button class="submit-button" style="position: absolute; right: 5px;" @click="submitQuestion(round,q)">
                button
              </button>
              <div>
                <input v-model="questionMatrix[[round,q]]" /> 
              </div>
              <div>
                <input v-model="answerMatrix[[round,q]]" /> 
              </div>
            </div>
          </span>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
const config = require("../../assets/config");
import TitleHelper from '../../helpers/vueTitleHelper.vue';

export default {
  name: "QLL",
  beforeCreate: function() {
    document.body.className = 'qll';
  },
  data() {
    return {
        values: config,
        questionCount: null,
        source: null,
        errorValue: null,
        questionDetails: null,
        logged: [],
        fullSet: null,
        round_labels: [1,2,3,4,5,6,7,8,9,'spares'],
        question_labels: ['1A','1B','2A','2B','3A','3B','4A','4B'],
        spare_question_labels: [1,2,3,4,5,6,7,8,9,10],
        questionMatrix: {},
        answerMatrix: {},
        idMatrix: {},
        monthNames: {
         1: 'January',
         2: 'February',
         3: 'March',
         4: 'April',
         5: 'May',
         6: 'June',
         7: 'July',
         8: 'August',
         9: 'September',
         10: 'October',
         11: 'November',
         12: 'December'
        },
    };
  },
  mounted() {
    if (this.$route.params.pathMatch) {
        this.source = this.$route.params.pathMatch.replace('_','.');
      }
    this.getGame(this.source);
    this.countQuestions();
  },
  components: {
    TitleHelper,
  },
  methods: {
    countQuestions: function() {
      var config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      var body = {};
      var url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/qll/count";
      axios
        .get(url,body,config)
        .then(response => {
          this.questionCount = parseInt(response.data);
        })
        .catch(error => {
          this.errorValue = error;
        })
    },
    getGame: function(source) {
      var config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      var body = { 'params': {'source': source }};
      var url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/qll/game";
      axios
        .get(url,body,config)
        .then(response => {
          this.fullSet = response["data"];
          for (let n of this.fullSet) {
            this.questionMatrix[[n.round_number, n.question_number]] = n.question;
            this.answerMatrix[[n.round_number, n.question_number]] = n.answer;
            this.idMatrix[[n.round_number, n.question_number]] = n._id;
          }
        })
        .catch(error => {
          this.errorValue = error;
        })
    },
    submitQuestion: function(round,q) {
      let knownId = null;
      if ([round,q] in this.setMaker && 'id' in this.setMaker[[round,q]]) {
        knownId = this.setMaker[[round,q]]['id']
      }
      if (knownId) {
        let mongoObject = {
          'id': knownId,
          'question': this.questionMatrix[[round,q]],
          'answer': this.answerMatrix[[round,q]],
        }
        var config = {
          headers: {
            "Content-Type": "application/json"
          }
        };
        var body = { 'object': mongoObject };
        var url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/qll/amend";
        axios
          .put(url,body,config)
          .then(() => {
            this.getGame(this.source);
          })
          .catch(error => {
            this.errorValue = error;
          })
      } else {
        let mongoObject = {
          'source': this.fullSet[0]['source'],
          'dateCorrect': this.fullSet[0]['dateCorrect'],
          'datePlayed': this.fullSet[0]['datePlayed'],
          'round_number': round,
          'question_number': q,
          'question': this.questionMatrix[[round,q]],
          'answer': this.answerMatrix[[round,q]],
          'author': this.fullSet[0]['author'],
          'purpose': this.fullSet[0]['purpose']
        }
        if (this.fullSet[0]['subject']) {
          mongoObject['subject'] = this.fullSet[0]['subject'];
        }
        config = {
          headers: {
            "Content-Type": "application/json"
          }
        };
        body = { 'object': mongoObject };
        url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/qll/add";
        axios
          .post(url,body,config)
          .then(() => {
            this.getGame(this.source);
            this.countQuestions();
          })
          .catch(error => {
            this.errorValue = error;
          })
      }
    },
    flagQuestion: function(id) {
      var config = {
        headers: {
          "Content-Type": "application/json"
        }
      }
      var body = { 'item': id };
      var url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/qll/report";
      axios
        .put(url,body,config)
        .then(() => {
          this.logged.push(id);
        })
        .catch(error => {
          this.errorValue = error;
        })
    },
    unflagQuestion: function(id) {
      var config = {
        headers: {
          "Content-Type": "application/json"
        }
      }
      var body = { 'item': id };
      var url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/qll/unreport";
      axios
        .put(url,body,config)
        .then(() => {
          this.logged = this.logged.filter((p) => { return p != id });
        })
        .catch(error => {
          this.errorValue = error;
        })
    },
  },
  computed: {
    setMaker: function() {
      let tempSet = {}
      if (this.fullSet){
        for (let n of this.fullSet) {
          tempSet[[n.round_number, n.question_number]] = {'question':n.question, 'answer':n.answer, 'id': n._id};
          if ('status' in n) {
            tempSet[[n.round_number, n.question_number]]['status'] = n.status;
          }
        }
      }
      return tempSet;
    },
  },
  watch: {
    questionMatrix: {
      deep: true,
      handler() {
        console.log('question change');
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#qll-page {
  padding: 8px;
  min-height: calc(100vh - 16px);
  background-color: black;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #3333dd;
}

body {
  margin: 0px;
}

div.question {
  border-radius: 5px;
  margin: 5px;
  border: 1px solid #118;
  text-align: left;
  padding: 10px;
}

div.question.problem {
  background-color: #711;
  border: 1px solid #911;
  color: #400;
}

div.question-text {
  font-weight: bold;
}

div.answer-text {
  font-weight: normal;
  text-align: right;
  color: #11a;
}

div.question.problem div.answer-text {
  font-weight: normal;
  text-align: right;
  color: #100;
}

div.provenance {
  font-size: 8px;
  color: #ccc;
}

button.submit-button {
  font-family: 'Avenir';
  background-color: #11a;
  color: #66c;
  border: none;
  border-radius: 5px;
}

button.hidden {
  display: none;
}

span.reported {
  color: red;
  left: 5px;
  position: relative;
}

button.unreport-button {
  position: relative;
  font-family: 'Avenir';
  background-color: #a22;
  color: #400;
  border: none;
  border-radius: 5px;
  left: 10px;
}

input {
  background-color: black;
  color: greenyellow;
  border: 1px solid green;
  padding: 2px;
  font-family: "Avenir";
  width: 90%;
}

</style>

